import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datetime';

import { FIELDS } from '../../../../../../../../bi/constants/trips';
import { DEFAULTTIME, DEFAULTDATE, LOCALERU } from '../../../../../../../../constants/time';

import style from '../../../../../../styles/form.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const LABELS = {
  ARRIVAL_AIRPORT: 'Место прилета',
  ARRIVAL_DATE: 'Дата прилета',
  ARRIVAL_TIME: 'Время прилета',
};

const ArrivalAirportFields = ({
  index,
  onChangeInput,
  validationSegments,
  checkoutDate,
  checkoutTime,
  arrivalAirport,
  onSelectSuggestAirport,
  onChangeSuggestAirport,
  renderDay,
  validation,
  renderErrorSegments,
}) => {
  const renderAutocompleteAirport = (item) => {
    const { Code, City, Country, Name } = item;

    return (
      <div className={ style['autocomplete-item'] }>
        { Code } -&nbsp;
        { City }&nbsp;
        { Country }&nbsp;
        { Name }
      </div>
    );
  };

  return (
    <div className={ style.row }>
      <div className={ `${style['col-1-3']}` }>
        <div className={ `${style['auto-input']}` }>
          <label>{ LABELS.ARRIVAL_AIRPORT }</label>
          <Autocomplete
            menuStyle={ MENUSTYLE }
            value={ arrivalAirport.label }
            items={ arrivalAirport.suggests }
            getItemValue={ item => item.Code }
            onSelect={ (cValue, value) => onSelectSuggestAirport(FIELDS.ARRIVAL_AIRPORT, value, index) }
            onChange={ (event, value) => onChangeSuggestAirport(FIELDS.ARRIVAL_AIRPORT, FIELDS.ARRIVAL_AIRPORT, value, index) }
            renderItem={ item => renderAutocompleteAirport(item) }
          />
          { renderErrorSegments(FIELDS.ARRIVAL_AIRPORT, index) }
        </div>
      </div>
      <div className={ style['col-1-3'] }>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat={ false }
          renderDay={ renderDay }
          locale={ LOCALERU }
          value={ checkoutDate }
          onChange={ value => onChangeInput(null, FIELDS.CHECKOUT_DATE, value, index) }
          onBlur={ value => validationSegments(value, FIELDS.CHECKOUT_DATE, index) }
          className={ `componentHook ${validation.Segments[index].CheckoutDate ? 'no-valid' : ''}` }
        />
        <label>{ LABELS.ARRIVAL_DATE }</label>
        { renderErrorSegments(FIELDS.CHECKOUT_DATE, index) }
      </div>
      <div className={ style['col-1-3'] }>
        <DatePicker
          dateFormat={ false }
          timeFormat={ DEFAULTTIME }
          locale={ LOCALERU }
          value={ checkoutTime }
          onChange={ value => onChangeInput(null, FIELDS.CHECKOUTTIME, value, index) }
          onBlur={ value => validationSegments(value, FIELDS.CHECKOUTTIME, index) }
          className={ `componentHook ${validation.Segments[index].CheckoutTime ? 'no-valid' : ''}` }
        />
        <label>{ LABELS.ARRIVAL_TIME }</label>
        { renderErrorSegments(FIELDS.CHECKOUTTIME, index) }
      </div>
    </div>
  );
};

ArrivalAirportFields.propTypes = {
  index: PropTypes.number.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  validationSegments: PropTypes.func.isRequired,
  checkoutDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  checkoutTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  arrivalAirport: PropTypes.object.isRequired,
  onSelectSuggestAirport: PropTypes.func.isRequired,
  onChangeSuggestAirport: PropTypes.func.isRequired,
  renderDay: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  renderErrorSegments: PropTypes.func.isRequired,
};

export { ArrivalAirportFields };
