import React from 'react';
import PropTypes from 'prop-types';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/trip.module.scss';

const LABELS = {
  COPY: 'Копировать',
  EDIT: 'Редактировать',
  ORDER_NUMBER: 'Номер заказа',
  FIO: 'ФИО',
  DATE: 'Даты',
  HOTEL: 'Отель',
  REGION: 'Регион',
  ROOM_CATEGORY: 'Категория комнаты',
  SUM: 'Сумма',
  CHANGE_NAME: 'Название изменения',
  NEW_ORDER: 'Новый заказ',
  DIRECTION: 'Направление',
  TOWN_AND_AIRPORT: 'Город и аэропорт',
  TYPE: 'Тип',
  ADDRESS_FROM: 'Адрес подачи',
  ADDRESS_TO: 'Адрес прибытия',
  CAR_CLASS: 'Класс машины',
  ROUTE: 'Маршрут',
};

const VersionTitle = ({
  serviceType,
}) => {
  switch (serviceType) {
    case SERVICETYPE.HOTEL: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.FIO }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.HOTEL }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.REGION }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.ROOM_CATEGORY }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.VIP_HALL: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.FIO }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.DIRECTION }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.TOWN_AND_AIRPORT }</label>
          </div>
          <div className={ styles['col-1-7'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.CUSTOM: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.FIO }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.TYPE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.BUS: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.FIO }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.ROUTE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.EVENT: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.TRANSFER: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.FIO }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.ADDRESS_FROM }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.ADDRESS_TO }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.CAR_CLASS }</label>
          </div>
          <div className={ styles['col-1-8'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    case SERVICETYPE.INSURANCE: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.ORDER_NUMBER }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.CHANGE_NAME }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.DATE }</label>
          </div>
          <div className={ styles['col-1-6'] }>
            <label>{ LABELS.SUM }</label>
          </div>
        </div>
      );
    }

    default: return (
      <div className={ styles.row }>
        <div className={ styles['col-1-5'] }>
          <label>{ LABELS.ORDER_NUMBER }</label>
        </div>
        <div className={ styles['col-1-4'] }>
          <label>{ LABELS.CHANGE_NAME }</label>
        </div>
      </div>
    );
  }
};

VersionTitle.propTypes = {
  serviceType: PropTypes.string.isRequired,
};

export { VersionTitle };
